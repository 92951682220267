html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  color: white;
  /* background: -webkit-linear-gradient(180deg, #224b58, #4a6f7a);
  background: linear-gradient(180deg, #224b58, #4a6f7a); */

  background-image: -webkit-linear-gradient(0deg, #23c6a5 -180%, #224b58);
  background-image: linear-gradient(0deg, #23c6a5 -180%, #224b58);

  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in 250ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in 50ms;
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.slide-enter {
  animation: slideIn 0.5s forwards;
}

.slide-exit {
  animation: slideOut 0.5s forwards;
}
