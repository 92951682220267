@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");

.App {
  text-align: center;
  background-color: #23c686 !important;
  font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif !important;
}

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0 !important;
  max-width: 100vw !important;
  padding: 0 !important;
  overflow: visible !important;
  touch-action: pan-x pan-y !important;
  overscroll-behavior: none !important;
  scroll-behavior: smooth !important;
}

.login-screen {
  /* background: white; */
  background: linear-gradient(180deg, #25414e, transparent);
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  justify-items: center;
  width: 570px;
  padding: 50px 25px;
  margin: 80px auto;
  border-radius: 17px;
  border-style: none;
  border: 2px solid #ffffff50 !important;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.supabase-auth-ui_ui-anchor {
  font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif !important;
  color: white !important;

  &:nth-of-type(2) {
    color: #23c686 !important;
    font-weight: 800;
    margin-top: 40px;
    font-size: medium;
    background-color: #25414e;
    border-radius: 10px;
    text-decoration: none;
    padding: 15px;
  }
}

.login-screen input {
  /* width: 500px; */
  justify-content: center;
  align-items: center;
}

.login-screen form button {
  background-color: #23c686 !important;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif !important;
}

.logoWhite {
  max-width: min(100%, 500px);
  height: auto;
  margin: 0 auto;
  /* filter: brightness(0); */
  align-self: center;
}

@media (max-width: 575px) {
  .login-screen {
    width: 85%;
    margin: 0 auto;
    margin-top: 50px;
  }
}

.supabase-auth-ui_ui-label {
  font-weight: 600;
  text-transform: uppercase;
  color: white !important;
  letter-spacing: -0.025rem !important;
  font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif !important;
}

.supabase-auth-ui_ui-container input,
.supabase-auth-ui_ui-container input:focus {
  background-color: #25414e !important;
  font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif !important;
  color: white !important;
}

.supabase-auth-ui_ui-container input:focus {
  border: 2px solid hsl(153 60% 53%);
}

.image-placeholder {
  width: 100%;
  height: 200px; /* Adjust as needed */
  background-color: #f0f0f0; /* Placeholder color */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-entrance {
  animation: fadeIn 500ms ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.route-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
