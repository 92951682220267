@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.selected-recipe-content {
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%) !important;
}

.selected-recipe-content body {
  color: black;
  overflow: hidden !important;
}

.ant-modal-content {
  padding: 0 !important;
  border-radius: 15px !important;
  border: 3px solid #23c686 !important;
  overflow: hidden;
  margin-bottom: 80px !important;
  background: linear-gradient(-45deg, #23c686, #1d3853) !important;
  box-shadow: inset 0px 2px 40px 3px rgba(0, 0, 0, 0.5) !important;
}

.ant-modal-wrap {
  -moz-backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding-bottom: 60px !important;
  margin-top: -40px !important;
}

.ant-modal-close-icon {
  font-size: 23px !important;
  padding: 4px 4px;
  border: 1px solid #ffffff80;
  position: absolute;
  top: 0;
  right: 1px;
  border-radius: 7px;
  background-color: #23c686 !important;
  background-color: #1d3853 !important;
  color: #fff !important;
}

.selected-recipe-content body {
  background: transparent;
  color: black;
  font-family: Montserrat, Arial, sans-serif;
  margin: 45px;
  margin-top: 10px;
  padding: 0;
  overflow: hidden;
}

.recipe-content-body {
  color: black;

  overflow: hidden !important;
}

.recipe-meta {
  text-align: center;
}

.selected-recipe-content h1,
.selected-recipe-content h2,
.selected-recipe-content h3,
.selected-recipe-content h4,
.selected-recipe-content h5 {
  color: #1d3853;
}

.selected-recipe-content h1 {
  color: #1d3853;
  font-size: 24px;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  line-height: 1;
  margin-bottom: 20px;
  text-align: center;
}

.selected-recipe-content h2 {
  color: #1d3853;
  font-size: 20px;
  margin-bottom: 10px;
}

.selected-recipe-content p {
  color: #000000;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
  display: block;
}

.selected-recipe-content .image-container {
  display: block;
  max-width: 100%;
  box-sizing: content-box;
}

.selected-recipe-content .image-container img {
  /* border-radius: 15px; */
  display: block;
  margin-top: 0;
  max-width: 100%;
  height: auto;
  object-fit: cover !important;
  overflow: visible !important;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.8) !important;
}

.selected-recipe-content ul {
  list-style-type: none;
  padding-left: 0;
}

.selected-recipe-content ul li:before {
  content: "•";
  color: #000000;
  display: inline-block;
  margin-right: 5px;
  font-weight: bold;
}

.selected-recipe-content ol {
  counter-reset: step;
  padding-left: 5px;
}

.selected-recipe-content b,
.selected-recipe-content i,
.selected-recipe-content li {
  display: inline-block !important;
}

.selected-recipe-content li {
  counter-increment: step;
  color: #000000;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 15px;
  list-style-type: none;
  display: flex;
}

.selected-recipe-content li {
  display: block !important;
  height: 100%;
}

.recipe-section {
  padding: 20px 24px;
}

.selected-recipe-content ol li:before {
  content: counter(step);
  background-color: #23c686;
  border-radius: 20%;
  color: #ffffff;
  display: inline-block;
  padding: 0 5px;
  min-width: 17px;
  height: 24px;
  line-height: 24px;
  margin-right: 10px;
  text-align: center;
  list-style-type: none;
  font-family: inherit;
  font-weight: bolder;
  font-size: 14px;
}

.selected-recipe-content table {
  width: 100%;
}

.selected-recipe-content td {
  text-align: left;
}

table {
  border: 0px solid transparent;
  border-collapse: collapse;
  text-align: center;
}

th {
  background-color: #23c686;
}

th:first-child {
  border-top-left-radius: 10px;
}

th:last-child {
  border-top-right-radius: 10px;
}

td {
  text-align: center !important;
}
