body {
  overflow: scroll !important;
}

.create-recipe-container {
  padding: 0 10%;
  margin: 0 auto;
  padding-bottom: 200px;
  max-width: 1200px !important;
}

form {
  padding: 25px !important;
}

label:only-child {
  font-weight: bold !important;
  line-height: 1 !important;
  font-size: medium !important;
}

@media (max-width: 575px) {
  .ant-steps-item-title {
    font-size: 13px !important;
  }

  label:only-child {
    font-size: small !important;
  }
}

.create-recipe-box {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #ffffff50 !important;
  /* background-image: linear-gradient(125deg, #fff1eb 0%, #ace0f9 100%); */
}

.ant-select-selection-placeholder {
  text-align: left !important;
}

.ant-float-btn {
  border-radius: 10px !important;
  padding: 10px !important;
  transition: filter 0.15s ease-in-out;
  opacity: 1;
  max-width: 200px;
  margin: 0 auto;
}

.ant-float-btn:hover {
  filter: brightness(1.2);
}

.ant-float-btn-body {
  width: 100% !important;
  background-color: transparent !important;
  font-weight: bolder !important;
}

.float-button-text {
  font-size: 15px;
}

.ant-collapse,
.ant-collapse-content {
  /* background: #FFFFFF50 !important; */
  border: 0;
  text-align: right;
}

.advanced-option-input {
  padding: 10px 10px 10px 10px !important;
  border-radius: 12px;
  /* border: 1px solid #ffffff20; */
  /* box-shadow: 0 1px 4px rgba(0,0,0,0.1); */
}

.advanced-option-input .ant-row {
  align-items: center !important;
  padding: 10px 0 !important;
}

@media (max-width: 575px) {
  .advanced-option-input {
    padding: 10px 10px 2px 10px !important;
    margin-bottom: 15px !important;
  }

  .create-recipe-container {
    padding: 0 15px;
    padding-bottom: 200px;
  }

  .ant-form-item .ant-form-item-label > label {
    display: inline !important;
  }
}

hr {
  margin: 0;
  padding: 0;
  border: 0;
  border-bottom: 1px solid #f5f5f580;
}

form h1,
form h2 {
  text-align: left;
}
