@import url("https://fonts.googleapis.com/css?family=Abril+Fatface");

.recipe-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  /* align-items: center; */
  max-width: 100vw;
  padding: 50px;
  color: black;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%) !important;
  background-attachment: fixed;
  font-family: "Montserrat", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  max-width: 1000px;
  padding-bottom: 80px;
}

.recipe-page-image-container img {
  width: 100%;
  border-radius: 15px;
}

ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  flex-direction: column;
}

ol li {
  counter-increment: my-awesome-counter;
  display: inline-block;
  flex-wrap: nowrap;
  width: 100%;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  /* margin-left: 0.5rem; */
  align-content: center;
  align-items: center;
}

ol li::before {
  content: counter(my-awesome-counter) "";
  font-weight: bold;
  background-color: #21ab75;
  color: white;
  /* font-size: 3rem; */
  margin-right: 0.5rem;
  /* margin-left: 0.5rem; */
  border-radius: 5px;
  padding: 6px;
  text-align: center;
  width: 20px;
  /* height: 50px; */
  text-align: center;
  justify-content: center;
  display: inline-block;
}

table {
  width: 100%;
  text-align: left !important;
}

table,
td,
th {
  /* width: 50%;  */
  text-align: left !important;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
