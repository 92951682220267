.navBar {
  display: flex !important;
  flex-direction: row !important;
  position: sticky;
  align-content: center !important;
  justify-content: center !important;
  justify-items: center !important;
  width: 100vw !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  height: 50px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1) !important;
}

.ant-menu-item,
.ant-menu-item-active,
.ant-menu-item-selected,
.ant-menu-overflow-item {
  display: flex !important;
  flex-direction: row;
  align-items: center !important;
  align-content: center !important;
  justify-items: center;
  white-space: normal !important;
  line-height: 1 !important;
  text-align: left !important;
  width: 25% !important;
  height: 100%;
  padding: 20px auto !important;
  z-index: 9999999;
}

.ant-menu-item-group-list .ant-menu-item {
  width: 25vw !important;
}

.ant-menu-item::after {
  /* min-width: 20%; */
  inset-inline: 10px;
}

@media (max-width: 575px) {
  .ant-menu-item,
  .ant-menu-item-active,
  .ant-menu-item-selected,
  .ant-menu-overflow-item {
    /* width: 100vw !important; */
    justify-content: center !important;
    margin: 20px auto !important;

    /* & span:not(a), .ant-menu-title-content, :not(a) {
      display: none;
      padding: 0 !important;
      width: 25vw;
    } */

    &::after {
      display: none;
    }
  }

  .ant-menu * {
    justify-content: center !important;
    justify-items: center !important;
  }

  .ant-menu-item-group-list {
    width: 100% !important;
  }

  .ant-menu-item-group-list * {
    display: revert !important;
    justify-content: revert !important;
    justify-items: revert !important;
  }

  .ant-menu-item-group-list .ant-menu-item,
  .ant-menu-item-group-list .ant-menu-item-selected {
    padding-left: 15px !important;
    padding-top: 12px !important;
    margin: 0 !important;
    width: 100% !important;
  }

  .navBar,
  .ant-menu {
    padding-bottom: 20px !important;
    box-shadow: none !important;
    bottom: 0 !important;
    position: fixed !important;
    z-index: 100;
  }

  .ant-menu .ant-menu-item .anticon {
    font-size: 18px;
    width: 25vw;
  }

  .ant-menu-submenu-popup,
  .ant-menu-vertical {
    width: 200px !important;
    margin-bottom: 80px !important;
  }
}

.create-recipe {
  background-color: #009e60 !important;
  color: white !important;
  font-weight: bold !important;
}

body {
  display: block;
  margin: 0;
}

.recipe-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.25s ease-in-out, outline 0.25s ease-in-out !important;
  outline: 0 !important;
  border: 0 !important;
  max-width: 320px;
  min-height: 350px;
  margin-bottom: 40px;
}

@media (max-width: 575px) {
  .recipe-card {
    max-width: 100%;
    margin: 15px !important;
    min-height: 350px;
    /* margin-bottom: 20px !important; */
  }
}

.ant-row {
  margin: 0 auto !important;
  justify-content: center !important;
}

.ant-list-item .ant-card-body div {
  border: 0 !important;
}

.diet-list-card {
  text-overflow: ellipsis;
}

.recipe-card:hover {
  transform: scale(1.02);
  transform-origin: top center;
  outline: 2.5px solid #23c686 !important;
}

.ant-card-head {
  background: linear-gradient(190deg, #f5f5f5, rgb(215, 236, 255)) !important;
  border-bottom: 1px solid #c0c0c080 !important;
  height: 100px;
}

.ant-card-body {
  padding: 0 0 15px 0 !important;
  border: 0 !important;
}

.ant-card-head-title,
.ant-card-head-title a,
.recipe-card-title,
.recipe-card-title a {
  text-align: center;
  white-space: normal;
  border: 0 !important;

  padding: 10px 5px 20px 5px;
  margin: 0 !important;
  margin-top: 15px !important;
  font-family: "Montserrat", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 700;
  color: inherit;
  text-decoration: none !important;
  letter-spacing: -0.02rem;
  /* margin: 10px 0 !important; */
  line-height: 1.1;
}

.recipe-image-container {
  display: flex;
  width: 100%;
  /* height: 120px; */
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;

  & img {
    display: inherit;
    position: sticky;
    margin: 0 !important;
    padding: 0 !important;
    top: 0 !important;
    left: 0;
    right: 0;
    overflow: hidden;
  }
}
